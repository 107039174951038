import React, { useEffect, useCallback, useState, useContext, useMemo } from 'react';
import moment from 'moment';
import { fetchPost } from '@/src/actions/common';
import { UbtHandler } from '@/src/ubt.v2/handler';
import CONFIG_URL from '@/src/constants/list/api';
import toNewListPageV2 from '@/src/sources/list/converter/toNewListPageV2';

// import moment from 'moment'
import { ExposeStat } from '@/src/ubt.v2/exposeStat';
import { ScopeInternationalContext } from '@/src/sources/list/scopeInternationalContext';
import { getNewFuzzyPage } from '@/src/utils/checkValidate';
import { RUNTIME_SERVER } from '@/src/constants/common';
import './nearByRecommend.scss';
import { RecommendCityInfo } from './types';

/**
 * 目的地临近地区推荐
 */
export const NearByRecommend = ({
	prevCond,
	setShowNearByRecommend,
	showNoresultRecommendHeader,
	nearByDateRecommendData
}) => {
	const { flightWay, dDate, dCityCode, aCityCode } = useMemo(() => {
		const flightWay = prevCond.get('flightWay');
		const flightSegments = prevCond.get('flightSegments');
		const segment = flightSegments.getIn([0]);
		const dDate = segment.getIn(['departureDate']);
		const dCityCode = segment.get('departureCityCode');
		const dAirportCode = segment.get('departureAirportCode');
		const aCityCode = segment.get('arrivalCityCode');
		const aCityName = segment.get('arrivalCityName');
		const cabin = prevCond.get('cabin');
		return { flightWay, dDate, dCityCode, dAirportCode, aCityCode, cabin, aCityName };
	}, [prevCond]);

	const onDateItemClick = useCallback(({ date, index }) => {
		const url = window.location.href.replace(/depdate=([0-9]|-)+/, `depdate=${date}`);
		UbtHandler.onUploadData('c_date_recommend_click', { index, date, url });
		toNewListPageV2(url, { Type: 'DateRecommend' });
	}, []);
	const onMoreLink = useCallback(({ date }) => {
		// 更多推荐日期
		const url = window.location.href.replace(/depdate=([0-9]|-)+/, `depdate=${date}`) + '&nearByDate';
		UbtHandler.onUploadData('c_date_recommend_more_click', { date, url });
		toNewListPageV2(url, { Type: 'DateRecommend' });
	}, []);
	const onDestinationItemClick = useCallback(({ departureCityCode, arrivalCityCode, date, index }) => {
		const url = `/oneway-${departureCityCode.toLowerCase()}-${arrivalCityCode.toLowerCase()}?depdate=${date}`;
		UbtHandler.onUploadData('c_place_recommend_click', { index, date, url });
		toNewListPageV2(url, { Type: 'CityRecommend' });
	}, []);
	const onMoreDestinationLink = useCallback(({ departureCityCode, arrivalCountryCode, date, dcn, acn }) => {
		// 更多推荐目的地
		const query = `from=CityRecommend&aCountry=${arrivalCountryCode}&dCity=${departureCityCode}&dDate=${date}&aCountryName=${acn}&dCityName=${dcn}`;
		UbtHandler.onUploadData('c_place_recommend_more_click', {
			departureCityCode,
			arrivalCountryCode,
			date,
			url: query
		});
		window.open(getNewFuzzyPage(`${RUNTIME_SERVER}/pricemap/?${query}`, query));
	}, []);
	const [cityList, setCityList] = useState<RecommendCityInfo[]>([]);
	const [destinationRecommendTit, setTitle] = useState('');
	const [switchOpen, setSwitchOpen] = useState(false);
	const enableFetchData = flightWay === 'S';
	const scopeInternational = useContext(ScopeInternationalContext);

	useEffect(() => {
		if (!enableFetchData || !scopeInternational) {
			return;
		}

		setCityList([]);
		fetchPost(CONFIG_URL.getDestinationRecommend, {
			departureCityCode: dCityCode,
			arrivalCityCode: aCityCode,
			departureDate: dDate
		})
			.then((res) => (res && res.data) || {})
			.then((response) => {
				if (!response) {
					
				} else {
					const data = response.data;
					if (data) {
						const hitAbt = data.hit;
						setSwitchOpen(hitAbt);

						if (data.recommendList && data.recommendList.length) {
							setCityList(data.recommendList);
							setTitle(data.title);
						}

						if ((nearByDateRecommendData.length || data.recommendList.length) && hitAbt) {
							setShowNearByRecommend(true);
						} else {
							setShowNearByRecommend(false);
						}
					}
				}
			});
	}, [dCityCode, aCityCode, dDate, enableFetchData, nearByDateRecommendData]);

	const hasNearByRecommend = !!(nearByDateRecommendData.length || cityList.length);
	const showNearByRecommend = showNoresultRecommendHeader && flightWay == 'S' && switchOpen && hasNearByRecommend;

	return showNearByRecommend ? (
		<React.Fragment>
			<h3 className="no-result-recommend-header">没有符合条件的结果，为您推荐</h3>
			{nearByDateRecommendData.length ? (
				<ExposeStat ubtKey="c_date_recommend_show">
					<div className="recommend-container nearByRecommend-container">
						<header>30天内其它日期出发的航班</header>
						<section className="content">
							<ul className="nearByRecommend-list clearfix">
								{nearByDateRecommendData.map((item, index) => (
									<li
										key={index}
										onClick={() => {
											onDateItemClick({ date: item.get('date'), index });
										}}
									>
										<div className="recommend-date">
											{`${moment(item.get('date')).format('MM-DD')}`} {item.get('week')}
										</div>
										<div className="recommend-price">
											<dfn>&yen; </dfn>
											{item.get('price')}
										</div>
									</li>
								))}
								<li className="nearByRecommend-more">
									<a
										onClick={() => {
											onMoreLink({
												date: nearByDateRecommendData && nearByDateRecommendData[0].get('date')
											});
										}}
									>
										更多 <i className="iconf-search-more">&#xe645;</i>
									</a>
								</li>
							</ul>
						</section>
					</div>
				</ExposeStat>
			) : null}
			{cityList.length ? (
				<ExposeStat ubtKey="c_place_recommend_show">
					<div className="recommend-container nearByRecommend-container">
						<header>{destinationRecommendTit}</header>
						<section className="content">
							<ul className="nearByRecommend-list clearfix">
								{cityList.map((city, index) => {
									const {
										departureCityCode,
										departureCityName,
										arrivalCityCode,
										arrivalCityName,
										departureDate,
										// description,
										salePrice,
										week
									} = city;
									return (
										<li
											key={index}
											onClick={() => {
												onDestinationItemClick({
													departureCityCode: departureCityCode,
													arrivalCityCode: arrivalCityCode,
													date: departureDate,
													index
												});
											}}
										>
											<div className="recommend-destination">
												{departureCityName}
												<i className="icon-ow-arrow"></i>
												{arrivalCityName}
												{/* {description && <span>{description}</span>} */}
											</div>
											<div className="recommend-date recommend-destination-date">
												{`${moment(departureDate).format('MM-DD')}`} {week}
											</div>
											<div className="recommend-price">
												<dfn>&yen; </dfn>
												{salePrice}
											</div>
										</li>
									);
								})}
								<li className="nearByRecommend-more recommend-destination-more">
									<a
										onClick={() => {
											onMoreDestinationLink({
												departureCityCode: cityList && cityList[0].departureCityCode,
												arrivalCountryCode: cityList && cityList[0].arrivalCountryCode,
												date: cityList && cityList[0].departureDate,
												acn: cityList && cityList[0].arrivalCountryName,
												dcn: cityList && cityList[0].departureCityName
											});
										}}
									>
										更多 <i className="iconf-search-more">&#xe645;</i>
									</a>
								</li>
							</ul>
						</section>
					</div>
				</ExposeStat>
			) : null}
		</React.Fragment>
	) : null;
};

export default NearByRecommend;
